import { Hotspot, ResourceList, Witness } from "@helium/http";
import { Badge, Checkbox, Chip, FormControl, FormControlLabel, IconButton, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Radio, RadioGroup, Skeleton, Typography } from "@mui/material";
import List from "@mui/material/List";
import { useEffect, useState } from "react";
import { unslugify } from "../../utils/unslugify";
import { createAvatar } from "@dicebear/avatars";
import * as avatarStyle from "@dicebear/avatars-gridy-sprites";
import HexagonIcon from "mdi-react/HexagonIcon";
import MapMarkerIcon from "mdi-react/MapMarkerIcon"
import MapMarkerDistanceIcon from 'mdi-react/MapMarkerDistanceIcon'
import * as d3 from "d3";
import distance from "@turf/distance";
import { point } from "@turf/helpers";
import heliumApi from "../../utils/helium/client";
import { MapControlConsumer, MapControlContext } from "../../utils/map/context";
import * as turf from '@turf/turf';

const WitnessList  = ({hotspot}:{hotspot:Hotspot | null}) => {
    const colorPick = d3.interpolateHsl('#FF6666', '#29D344');
    
    const [witness, setWitness] = useState<ResourceList<Witness> | null>(null);
    const address = hotspot?.address??"";
    const hscoord = point([hotspot?.lng??0.0, hotspot?.lat??0.0]);
    const [loading, setLoading] = useState<boolean>(false);
    const [checkedWitness, setCheckedWitness] = useState<Witness[]>([]);

    const loadWitness = async (addr:string) => {
        setWitness(null);
        setLoading(true);
        const witness = await heliumApi.hotspot(addr).witnesses.list();
        setWitness(witness);
        console.log(witness);
        setLoading(false);
    };

    const flyTo = (control: MapControlContext | null, w:Witness) => {
        if (control) {
            const point = turf.point([w.lng??0, w.lat??0], {...w});
            control?.selectHotspot(point);
        }
    };

    const setWitnessSource = (control: MapControlContext | null, ws: Witness[]) => {
        if (control) {
            const points = ws.map((w, i) => turf.point([w.lng??0, w.lat??0], {...w, _type: "line", _value: (i + 1)}));
            const centerPoint = [hotspot?.lng??0, hotspot?.lat??0];
            const lines = points.map(p => turf.lineString([centerPoint, p.geometry.coordinates], {_type: "line", _value: `${turf.distance(centerPoint, p.geometry.coordinates).toFixed(1)} km`}));
            
            control.displayWitness(turf.featureCollection(points), turf.featureCollection(lines));
        }
    }

    useEffect(() => {        
        loadWitness(address);  
        return () => {
            console.log("umount");
            setCheckedWitness([] as Witness[]);
        };  
    }, [address]);

    return <>
      {loading && (
        <List sx={{ width: '100%', maxWidth: '360', bgcolor: 'background.paper' }}>
            {[0, 1, 2].map(() => (<ListItem>
                <ListItemIcon>
                    <Skeleton variant="rectangular" width={20} height={20} />
                </ListItemIcon>
                <ListItemAvatar>
                    <Skeleton variant="rectangular" width={40} height={40} />
                </ListItemAvatar>
                <ListItemText primary={<Skeleton variant="text" width={200} height={24} />} secondary={(
                    <span>
                        <Skeleton variant="text" width={200} height={30} />
                    </span>)}/>
            </ListItem>))}
        </List>
        
      )}
      {!(witness?.data.length??0) && !loading && (
          <Typography>No witness in last 5 days</Typography>
      )
      }
      <List sx={{ width: '100%', maxWidth: '360', bgcolor: 'background.paper' }}>
        {witness?.data.map((w) =>{
            const avatar = createAvatar(avatarStyle, {
                seed: w.address
            });
            const wcoord = point([w?.lng??0.0, w?.lat??0.0]);
            return (
            <MapControlConsumer>
                {
                    control => {
                        return (
                        <ListItem secondaryAction={
                            <IconButton edge="end" aria-label="comments" onClick={() => flyTo(control, w)}>
                            <MapMarkerIcon />
                            </IconButton>
                        } key={w.address}>
                            <ListItemIcon>
                                <Checkbox
                                edge="start"
                                onChange={(event) => {
                                    var ws:Witness[];
                                    if(event.target.checked) {
                                        checkedWitness.push(w);
                                        ws = checkedWitness;
                                    } else {
                                        ws = checkedWitness.filter(t => t.address !== w.address);
                                        setCheckedWitness(ws);
                                    }
                                    setWitnessSource(control, ws);
                                    console.log(ws);
                                }}
                                tabIndex={-1}
                                />
                            </ListItemIcon>
                            <ListItemAvatar>
                                <Badge anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }} 
                                    color="secondary" 
                                    badgeContent={checkedWitness.findIndex(t => w.address === t.address) + 1}>
                                    <img width="32" height="32" src={`data:image/svg+xml;utf8,${encodeURIComponent(avatar)}`} alt="avatar"/>
                                </Badge>
                            </ListItemAvatar>
                            <ListItemText primary={unslugify(w.name??"")} secondary={(
                                <span>
                                    <HexagonIcon color={colorPick(w.rewardScale??0)} size="12"/>
                                    {(w.rewardScale??0).toFixed(2)}&nbsp;
                                    <MapMarkerDistanceIcon size="12" color="#1133AA"/>
                                    {distance(hscoord, wcoord).toFixed(1)} km
                                    {distance(hscoord, wcoord) < 0.3 && (<Chip color="warning" size="small" label="too close" />)}
                                </span>)}/>
                        </ListItem>
                    )}
                }
            </MapControlConsumer>
        );})}
    </List></>
}

export default WitnessList;