import { Map } from "mapbox-gl";

interface StyleImageInterface {
    width: number,
    height: number,
    context?: CanvasRenderingContext2D,
    data: Uint8Array | Uint8ClampedArray,
    map?: Map,
    render?: () => boolean,
    onAdd?: (map: Map, id: string) => void,
    onRemove?: () => void
};


export const makerImage = ([b, g, r]:number[], size:number) => {
    const pulsingDot:StyleImageInterface = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),
        
        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function (map:Map, id:string) {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d')!;
            this.map = map;
        },
        
        // Call once before every frame where the icon will be used.
        render: function () {
            const duration = 1000;
            const t = (performance.now() % duration) / duration;
            
            const radius = (size / 2) * 0.3;
            const outerRadius = (size / 2) * 0.7 * t + radius;
            const context = this.context!;
            
            // Draw the outer circle.
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
                this.width / 2,
                this.height / 2,
                outerRadius,
                0,
                Math.PI * 2
            );
            context.fillStyle = `rgba(${r*256}, ${g*256}, ${b*256}, ${1 - t})`;
            context.fill();
            
            // Draw the inner circle.
            this.context!.beginPath();
            this.context!.arc(
                this.width / 2,
                this.height / 2,
                radius,
                0,
                Math.PI * 2
            );
            context.fillStyle = `rgba(${r*256}, ${g*256}, ${b*256}, 1)`;
            context.strokeStyle = 'white';
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();
            
            // Update this image's data with data from the canvas.
            this.data = this.context!.getImageData(
                0,
                0,
                this.width,
                this.height
            ).data;
            
            // Continuously repaint the map, resulting
            // in the smooth animation of the dot.
            this.map?.triggerRepaint();
            
            // Return `true` to let the map know that the image was updated.
            return true;
        }
    }

    return pulsingDot;
}