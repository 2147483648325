import { Feature, FeatureCollection, LineString, Point } from "geojson";
import { createContext } from "react";

export interface MapControlContext {
    selectHotspot(hotspot:Feature):void;
    displayWitness(points: FeatureCollection<Point>, lines: FeatureCollection<LineString>):void;
    setMapFilter(filter:any[]):void;
    getResolutions():number[];
    setResolutions(res: number[]):void;
}

export const {Provider:MapControlProvider, Consumer:MapControlConsumer} = createContext<MapControlContext | null>(null);
