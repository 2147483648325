import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import React, { useCallback } from "react";
import ReactMapGL, {GeolocateControl, Layer, NavigationControl, ScaleControl, Source, MapEvent, MapRef, AttributionControl, FlyToInterpolator, ViewportProps} from 'react-map-gl';
import { geolocateControlStyle, navControlStyle, hotspotPointStyle, gridsColors } from "../../utils/map/mapStyle";
import { makerImage } from "./markerImage";
import InfoBox from "../../components/InfoBox";
import Container from "@mui/material/Container";
import DrawerHeader from "../../components/DrawHeader";
import { MapControlProvider } from '../../utils/map/context';
import { Feature, FeatureCollection, LineString, Point } from 'geojson';
import { featureCollection } from '@turf/helpers';
import * as h3js from 'h3-js';
import * as turf from '@turf/turf';
import * as d3 from "d3";
import mapboxgl from "mapbox-gl";
// eslint-disable @typescript-eslint/no-var-requires 
// eslint-disable-next-line import/no-webpack-loader-syntax
(mapboxgl as any).workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;


const TILE_URL = "https://tiles.cowbay.wtf/data/hotspots/{z}/{x}/{y}.pbf";


const PageMap = () => {
    const mapContainerRef = React.useRef<HTMLDivElement>(null);
    const drawerRef = React.useRef<HTMLDivElement>(null);
    const mapRef = React.useRef<MapRef>(null);
    const [mapHeight, setMapHeight] = React.useState<number>(100);
    const [mapWidth, setMapWidth] = React.useState<number|string>("100%");
    const [viewport, setViewport] = React.useState<ViewportProps>({
        longitude: -122.45,
        latitude: 37.78,
        zoom: 8
      });
    const [selectedHotSpot, selectHotspot] = React.useState<Feature>();
    const [witnessPoints, setWitnessPoints] = React.useState<FeatureCollection<Point>>();
    const [witnessConnects, setWitnessConnects] = React.useState<FeatureCollection<LineString>>();
    const [filter, setFilter] = React.useState<any[]>(['all']);
    const [resolutions, setResolutions] = React.useState<number[]>([]);
    
    const hexToRing = (index:string, size:number) => {
        const h3ring = h3js.kRing(index, size);
        const polys = h3ring.map(h => {
            const p = h3js.h3ToGeoBoundary(h, true);
            return turf.helpers.polygon([p]);
        });

        return turf.featureCollection(polys);
    }

    const showS3Grid = (res: number[]) => {
        if(!selectedHotSpot || !res.length || !res.map(r => r>=4 && r<=10).reduce((p, c) => p&&c)) {
            return;
        }
        
        const selectedCoord = (selectedHotSpot.geometry as Point).coordinates;
        return [4, 5, 6, 7, 8, 9, 10].map(r => {
            const h3index = h3js.geoToH3(selectedCoord[1], selectedCoord[0], r);
            const fillColor = gridsColors[r-4] ? gridsColors[r-4]:d3.color("red");
            const lineColor = fillColor!.darker();  
            const data = res.indexOf(r) >= 0 ? hexToRing(h3index, 4):turf.featureCollection([] as turf.helpers.Feature<turf.helpers.Polygon, turf.helpers.Properties>[]);      
            return (
                <Source id={`hex-src-${r}`} type="geojson" data={data}>
                    <Layer id={`hex-${r}`} type="line" paint={{'line-width':1, 'line-opacity': 0.3,'line-color': lineColor.toString()}} />
                    <Layer id={`hex-fill-${r}`} type="fill" paint={{'fill-opacity':0.15,'fill-color': fillColor?.toString()}} />
                </Source>
            );
        });
    }    

    const interactiveLayers = selectedHotSpot ? ['selected-hotspot-layer', 'hotspot-layer']:['hotspot-layer'];
    const resetMapHeight = () => {
        if(mapContainerRef.current) {
            const h = window.innerHeight - mapContainerRef.current.offsetTop
            setMapHeight(h);
        }
    };

    const resetMapWidth = () => {
        if(mapContainerRef.current && drawerRef.current) {
            const w = mapContainerRef.current.offsetWidth - drawerRef.current.offsetWidth;
            setMapWidth(w);
        } else {
            setMapWidth("100%");
        }
    }

    const onSelectHotSpot = useCallback((selected:Feature) => {
        if(selected) {
            setWitnessConnects(featureCollection([] as Feature<LineString>[]));
            setWitnessPoints(featureCollection([] as Feature<Point>[]));
            selectHotspot(selected);

            const center = (selected.geometry as Point).coordinates;
            setViewport({
                latitude: center[1],
                longitude: center[0],
                zoom: 12,
                transitionDuration: 1000,
                transitionInterpolator: new FlyToInterpolator()
            });
        }      
    }, []);

    const displayWitness = (points:FeatureCollection<Point>, lines:FeatureCollection<LineString>) => {
        console.log(lines);
        setWitnessPoints(points);
        setWitnessConnects(lines);
    }

    const handleOnClick = (event:MapEvent) => {
        if(event.features) {
            const selected = event.features.find(f => f.layer.id === 'hotspot-layer');
            
            if(mapRef.current) {
                const map = mapRef.current.getMap();
                if(selected) {
                    console.log(selected);
                    const layer = selected.layer;
                    const {a, b, g, r} = layer.paint['circle-color'];
                    
                    if(map.hasImage('hotspot-selected')) {
                        map.removeImage('hotspot-selected');
                    } 
                    map.addImage('hotspot-selected', makerImage([b, g, r], 100));
                    
                    onSelectHotSpot(selected);
                    setFilter(["all"]);
                } else {
                    setWitnessConnects(featureCollection([] as Feature<LineString>[]));
                    setWitnessPoints(featureCollection([] as Feature<Point>[]));
                    selectHotspot(undefined);
                    setFilter(["all"]);   
                }   
            }
        }
    };
   
    React.useEffect(() => {
        resetMapHeight();
        resetMapWidth();
    });

    window.onresize = () => {
        resetMapHeight();
        resetMapWidth();
    }
    
    return (
    <Container maxWidth="xl" disableGutters={true} ref={mapContainerRef} style={{width:"100%"}} >
        <ReactMapGL
            mapboxApiAccessToken="pk.eyJ1Ijoiamxuc2hlbiIsImEiOiJja3RtaGZ6a3AwOTFvMnBuNTVia3hieDc3In0.quMGCNNjDYl6PiT_6HiEVw"
            {...viewport}
            width={mapWidth}
            height={mapHeight}
            onViewportChange={setViewport}
            mapStyle="mapbox://styles/mapbox/light-v10"
            minZoom={2}
            maxZoom={18}
            onClick={handleOnClick}
            ref={mapRef}
            attributionControl={false}
            interactiveLayerIds={interactiveLayers}
            >
                <ScaleControl maxWidth={100} unit="metric" style={{left: 40, bottom:50}} />
                <GeolocateControl
                    style={geolocateControlStyle}
                    positionOptions={{enableHighAccuracy: true}}
                    trackUserLocation={true}
                    auto
                />
                <NavigationControl style={navControlStyle} showZoom={true} showCompass={false}/>                
                <Source id="hotspots" type="vector" tiles={[TILE_URL]} minzoom={0} maxzoom={18}>
                  <Layer id="hotspot-layer" type="circle" paint={hotspotPointStyle} source-layer='hotspots' filter={filter}/>
                </Source>
                {selectedHotSpot && (<Source id="selected-hotspot" type="geojson" data={selectedHotSpot}>
                  <Layer id="selected-hotspot-layer" type="symbol" paint={{}} layout={{'icon-image':'hotspot-selected'}} source="selected-hotspot" /> 
                </Source>)}
                <Source id="witness-connects" type="geojson" data={witnessConnects}>
                  <Layer id="witness-line-layer" type="line" paint={{"line-color":"yellow", "line-width":4}} source="witness-lines" /> 
                </Source>
                <Source id="witness-points" type="geojson" data={witnessPoints}>
                  <Layer id="witness-point-circle-layer" type="circle" paint={{"circle-color":"purple", "circle-radius":10, "circle-stroke-color": "lightgrey", "circle-stroke-width": 2}} source="witness-points" /> 
                  <Layer id="witness-point-layer" type="symbol" paint={{"text-color":"white"}} layout={{"text-field":"{_value}"}} source="witness-points" /> 
                </Source>                
                {selectedHotSpot && showS3Grid(resolutions)}
        </ReactMapGL>
        {selectedHotSpot && (
            <Drawer anchor="right"
                open={selectedHotSpot!==null}
                hideBackdrop={true}
                variant="persistent"  
            >
                <DrawerHeader />
                <MapControlProvider value={{
                    selectHotspot: onSelectHotSpot, 
                    displayWitness: displayWitness, 
                    setMapFilter: setFilter,
                    getResolutions: () => resolutions,
                    setResolutions: setResolutions
                     }}>
                    <Box sx={{width:360}} ref={drawerRef}>
                        <InfoBox name={selectedHotSpot.properties?.name} address={selectedHotSpot.properties?.address}/>
                    </Box>
                </MapControlProvider>
            </Drawer>
        )}
        
    </Container>
    )
};

export default PageMap;