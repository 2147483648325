import { Counts } from "@helium/http";
import { Card, CardContent, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import heliumApi from "../../utils/helium/client";

const Dashboard = () => {
    const [marketPrice, setMarketPrice] = useState<number>(-1);
    const [counts, setCounts] = useState<Counts | null>(null);

    const numFormat = new Intl.NumberFormat('en-US');
    
    const loadMarketPrice = async () => {
        const data = await (await fetch('https://api.coingecko.com/api/v3/coins/helium')).json();
        const usdTicker = (data.tickers as any[]).find(e => e.target === 'USD');

        if (usdTicker !== null) {
            setMarketPrice(usdTicker.last);
        }
    };

    const loadStat = async () => {
        setCounts(await heliumApi.stats.counts());
    };

    const loadData = async () => {
        await loadMarketPrice();
        await loadStat();
    }

    useEffect(() => {
        loadData();
    }, [marketPrice])

    return <>
    <Grid container spacing={1} sx={{padding: 2}}>
        <Grid item xs={3}>
            <Card>
                <CardContent>
                    <Typography>
                    Market Price 
                    </Typography>
                    {marketPrice === -1 && (<Skeleton variant="text" />)}
                    {marketPrice > 0 && (<span>${marketPrice.toFixed(2)}</span>)}
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={3}>
            <Card>
                <CardContent>
                    <Typography>
                    Hotspots
                    </Typography>
                    {!counts && (<Skeleton variant="text" />)}
                    {counts && (<span>{numFormat.format(counts?.hotspots)}</span>)}
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={3}>
            <Card>
                <CardContent>
                    <Typography>
                    Block Height
                    </Typography>
                    {!counts && (<Skeleton variant="text" />)}
                    {counts && (<span>{numFormat.format(counts?.blocks)}</span>)}
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </>;
}

export default Dashboard;