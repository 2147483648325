import { faClipboard, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Hotspot } from "@helium/http";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";
import { useEffect, useState } from "react";
import TabPanel from "../TabPanel";
import { createAvatar } from "@dicebear/avatars";
import * as avatarStyle from "@dicebear/avatars-gridy-sprites";
import './style.css'
import { unslugify } from "../../utils/unslugify";
import StyledBadge from "../StyledBadge";
import { TinyTab, TinyTabs } from "./TinyTab";
import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Circle } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { formatDistanceToNow } from "date-fns/esm";
import { getMakerName } from "../../utils/helium/maker";
import WitnessList from "./WitnessList";
import heliumApi from "../../utils/helium/client";
import RewardInfo from "./RewardInfo";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import { MapControlConsumer, MapControlContext } from "../../utils/map/context";

function shorten(s?:string, maxLen?:number):string {
    if(!s || !maxLen) {
        return "";
    }

    var t = s;
    if(s.length > maxLen) {
        t = s.substring(0, (maxLen - 3)/2) + "..." + s.substring(s.length - (maxLen - 3)/2);
    }
    return t;
}

function copyStrToClipboard(text:string | undefined) {
    if(text) {
        navigator.clipboard.writeText(text);
    }
}

function formatDateStr(str:string | undefined):string {
    if(!str) return "";
    const date = new Date(str);
    return `${format(date, "yyyy/MM/dd")} (${formatDistanceToNow(date)} ago)`;
}

const InfoBox = ({name, address}:{name:string, address:string}) => {
    const [selectedTab, setTab] = useState<number>(0);
    const [hotspot, setHotspot] = useState<Hotspot|null>(null);
    const [makerName, setMakerName] = useState<string>("");
    const [ownerFilter, setOwnerFilter] = useState<boolean>(false);
    const [makerFilter, setMakerFilter] = useState<boolean>(false);
    
    const avatar = createAvatar(avatarStyle, {
        seed: address
    });

    const handleTabChange = (event: React.SyntheticEvent, tabNum: number) => {
        setTab(tabNum);
    };

    const loadHotSpot = async(address:string) => {
        const h = await heliumApi.hotspots.get(address);
        console.log(h);
        setMakerName(await getMakerName(h.payer));
        setHotspot(h);
    };

    const isRelayed = () => {
        const listenAddrs = hotspot?.status?.listenAddrs;
        const IP = /ip4/g;
        return !!(
            listenAddrs &&
            listenAddrs.length > 0 &&
            !listenAddrs.find((a) => a.match(IP))
        );
    }

    const syncStatus = () => {
        const online = hotspot?.status?.online==="online";
        const color = online ? '#44b700':'#b74400';
        const syncTime = hotspot?.status?.timestamp ? `${formatDistanceToNow(new Date(hotspot?.status.timestamp??""))} ago`:"";
        return <>
            <Circle sx={{color: color, verticalAlign: "top"}} fontSize="small"/>{online ? "Online":"Offline"} ({syncTime})
        </>
    }

    const filterSwithcChanged = (mapControl: MapControlContext | null, filterType: 'owner' | 'maker', checked: boolean, value: string) => {
        if (!mapControl) return;
        if(checked) {
            switch(filterType) {
                case 'owner':
                    setOwnerFilter(true);
                    setMakerFilter(false);
                    mapControl.setMapFilter(['==', 'owner', value]);
                    break;
                case 'maker':
                    setOwnerFilter(false);
                    setMakerFilter(true);
                    mapControl.setMapFilter(['==', 'payer', value]);
                    break
            }
        } else {
            setOwnerFilter(false);
            setMakerFilter(false);
            mapControl.setMapFilter(['all']);
        }
    }

    useEffect(() => {
        loadHotSpot(address);
        return () => {
            setOwnerFilter(false);
            setMakerFilter(false);
        }
    }, [address]);
    
    return <> 
      <Box sx={{padding: "4px"}}>
          <Typography variant="h6" sx={{textAlign: 'center'}}>
              <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        online={hotspot?.status?.online === "online"}
                        >
                <img width="60" height="60" src={`data:image/svg+xml;utf8,${encodeURIComponent(avatar)}`} alt="avatar"/>
              </StyledBadge>
              {unslugify(name)}
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TinyTabs 
                value={selectedTab} 
                onChange={handleTabChange} 
                variant="fullWidth"
                aria-label="hotspot tabs" >
              <TinyTab label="Info" />
              <TinyTab label="Rewards" />
              <TinyTab label="Witness" />
            </TinyTabs>
          </Box>
          <TabPanel value={selectedTab} index={0}>
            {hotspot && (
            <List sx={{ width: '100%', maxWidth: '360', bgcolor: 'background.paper' }}>
                <ListItem secondaryAction={
                    <Tooltip title="Copy address to clipboad">
                        <IconButton edge="end" aria-label="copy" size="small" onClick={()=>{copyStrToClipboard(hotspot.address)}}> 
                        <FontAwesomeIcon icon={faClipboard} />
                        </IconButton>
                    </Tooltip>
                  }>
                    <Tooltip title={hotspot.address}>
                        <ListItemText  primary="Hotspot address" secondary={shorten(hotspot.address, 35)}/>
                    </Tooltip>
                </ListItem>
                <ListItem >
                    <Tooltip title={(<div>
                        {hotspot.owner??""}
                            <IconButton edge="end" aria-label="copy" size="small" onClick={()=>{copyStrToClipboard(hotspot.owner)}}>
                                <FontAwesomeIcon icon={faClipboard} color="white"/>
                            </IconButton>
                        </div>)}>
                        <ListItemText primary="Wallet" secondary={shorten(hotspot.owner, 35)}/>
                    </Tooltip>

                    <Tooltip title="Filter with owner">
                        <MapControlConsumer>
                        {control => <Switch
                            edge="end"
                            size="small"
                            checked={ownerFilter}
                            onChange={(e)=>filterSwithcChanged(control, "owner", e.target.checked, hotspot.owner??"")}
                            />}
                        </MapControlConsumer>
                    </Tooltip>
                </ListItem>
                <ListItem secondaryAction={
                    <Tooltip title="Go to location">
                        <IconButton edge="end" aria-label="copy" size="small" onClick={()=>{window.open(`https://earth.google.com/web/search/${hotspot.lat},${hotspot.lng}`)}}> 
                        <FontAwesomeIcon icon={faLocationArrow} />
                        </IconButton>
                    </Tooltip>
                  }>
                    <ListItemText primary="Location" secondary={`${hotspot.geocode?.longCountry} ${hotspot.geocode?.longCity}`}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Elevation" secondary={`${hotspot.elevation} m`}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Added" secondary={formatDateStr(hotspot.timestampAdded)}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Relayed" secondary={isRelayed()?"Yes":"No"}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Gain" secondary={`${(hotspot.gain??0)/10} dBi`}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Sync Status" secondary={syncStatus()}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Maker" secondary={makerName}/>
                    <Tooltip title="Filter with maker">
                        <MapControlConsumer>
                        {control => <Switch
                            edge="end"
                            size="small"
                            checked={makerFilter}
                            onChange={(e)=>filterSwithcChanged(control, "maker", e.target.checked, hotspot.payer??"")}
                            />}
                        </MapControlConsumer>
                    </Tooltip>
                </ListItem>
            </List>
            )}
            
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
              <div style={{padding:4}}></div>
              <MapControlConsumer>
                  {
                      control => <RewardInfo hotspot={hotspot} mapControl={control}/>
                  }
              </MapControlConsumer>
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
             <WitnessList hotspot={hotspot} />
          </TabPanel>
      </Box>
      </>;
}

export default InfoBox;