import { Bucket, Hotspot, NaturalDate, Sum } from "@helium/http";
import { Skeleton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { format, setDate, subMonths, endOfMonth } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { Bar, ComposedChart, Line, Tooltip, TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import heliumApi from "../../utils/helium/client";
import HexagonIcon from "mdi-react/HexagonIcon";
import { gridsColors } from "../../utils/map/mapStyle";
import { MapControlContext } from "../../utils/map/context";

const SELECTION_REWARD_7D = "0";
const SELECTION_REWARD_14D = "1";
const SELECTION_REWARD_30D = "2";
const SELECTION_REWARD_THIS_MONTH = "3";
const SELECTION_REWARD_LAST_MONTH = "4";

interface Earning {
    total: number;
    thismonth: number;
    lastmonth: number;
}

const RewardInfo = ({hotspot, mapControl}:{hotspot:Hotspot | null, mapControl:MapControlContext | null}) => {
    const address = hotspot?.address??"";
    const rewardCardRef = useRef<HTMLSpanElement>(null);
    const [rewardSum, setRewardSum] = useState<Sum[]|null>(null);
    const [earnings, setEarnings] = useState<Earning | null>(null);
    const [rewardSelection, setRewardSelection] = useState<string>(SELECTION_REWARD_7D);
    const resolutions = mapControl?.getResolutions()??[];
    const setResolutions = mapControl?.setResolutions?? ((res: number[]) => {});

    var start = new Date();

    const startOfThisMonth = setDate(start, 1);
    startOfThisMonth.setHours(0);
    startOfThisMonth.setMinutes(0);

    const startOfLastMonth = subMonths(startOfThisMonth, 1);
    
    const thisMonthEarn = (earn: number) => {
        const date = new Date();
    
        return <Typography sx={{fontSize:16}}>
            {format(date, "MMM")} <b>{earn.toFixed(2)}</b>
        </Typography>
    }
    
    const lastMonthEarn = (earn: number) => {
        const date = subMonths(new Date(), 1);
    
        return <Typography sx={{fontSize:16}}>
            {format(date, "MMM")} <b>{earn.toFixed(2)}</b>
        </Typography>
    }

    const RewardTooltips = ({
        active,
        payload,
        label,
      }: TooltipProps<ValueType, NameType>) => {
        if (active && payload && payload.length) {
            const d = Date.parse(payload[0].payload.timestamp);
            
            return (
                <div className="custom-tooltip">
                    <p>{format(d, "EEE MMM d")}</p>
                    <p>Total: {payload[0].payload.total.toFixed(2)} HNT</p>
                </div>
            );
        }
    
        return null;
    };

    const handleRewardSelectionChange = (event:SelectChangeEvent) => {
        setRewardSelection(rewardSelection => event.target.value);
    }

    const HexToggle = (resolution:number) => {
        return <ToggleButton value={resolution} aria-label={`Resolution ${resolution}`}>
            <HexagonIcon size="25" color={resolutions.findIndex(e => e === resolution) >= 0 ? gridsColors[resolution - 4]!.toString():"grey"}/>
            <span style={{
                color: "white",
                fontSize: 9,
                position: 'absolute', 
                top: 0, left: 0, 
                width: '100%', height: '100%', 
                verticalAlign: 'middle', textAlign: 'center', 
                lineHeight: 4}}>{resolution}</span>
        </ToggleButton>
    }

    const handleToggleChange = (e: React.MouseEvent<HTMLElement, MouseEvent>, values: any) => {
        setResolutions(values);
    }

    useEffect(() => {
        const loadRewards = async(address:string, selection: string) => {
            const listParams:{
                minTime?: Date | NaturalDate;
                maxTime?: Date;
                bucket?: Bucket;
                cursor?: string;
            } = {
                bucket: "day",
                maxTime: new Date()
            };
    
            switch (selection) {
                case SELECTION_REWARD_7D:
                    listParams.minTime = "-7 day";
                    break;
                case SELECTION_REWARD_14D:
                    listParams.minTime = "-14 day";
                    break;
                case SELECTION_REWARD_30D:
                    listParams.minTime = "-30 day";
                    break;
                case SELECTION_REWARD_THIS_MONTH:
                    listParams.minTime = startOfThisMonth;
                    break;
                case SELECTION_REWARD_LAST_MONTH:
                    
                    listParams.minTime = startOfLastMonth;
                    listParams.maxTime = endOfMonth(startOfLastMonth);
                    break;
            }
            const sum = await heliumApi.hotspot(address).rewards.sum.list(listParams);
            const sorterd = sum.data.sort((a:Sum, b:Sum) => Date.parse(a.timestamp) - Date.parse(b.timestamp));
            setRewardSum(sorterd);
        };
        
        loadRewards(address, rewardSelection);
    }, [address, rewardSelection]);

    useEffect(() => {
        const loadEarning = async (addr:string, startTime: Date) => {
            const earning:Earning = {
                total: 0,
                thismonth: 0,
                lastmonth: 0
            }
            
            earning.total = (await heliumApi.hotspot(addr).rewards.sum.get(startTime, new Date())).total;
            earning.thismonth = (await heliumApi.hotspot(addr).rewards.sum.get(startOfThisMonth, new Date())).total;
            earning.lastmonth = (await heliumApi.hotspot(addr).rewards.sum.get(startOfLastMonth, endOfMonth(startOfLastMonth))).total;
            setEarnings(earning);
        };

        loadEarning(address, hotspot?.timestampAdded ? new Date(hotspot.timestampAdded):new Date());
    }, [address]);
    
    return <>
    <Grid container spacing={1} sx={{paddingBottom: 1}}>
        <Grid item xs={12}>
            <ToggleButtonGroup value={resolutions} onChange={handleToggleChange}>
                {[10, 9, 8, 7, 6, 5, 4].map(r => HexToggle(r))}
            </ToggleButtonGroup>
        </Grid>
        <Grid item xs={6}>
            <Card variant="outlined" sx={{height:102}}>
                <CardContent >
                {earnings && (
                    <>
                    <Typography sx={{fontSize:16}}>
                        Total rewards
                    </Typography>
                    <Typography sx={{fontSize:22, fontWeight: "bold", textAlign: "center"}}>
                        {earnings.total.toFixed(2)}
                    </Typography>
                    <Typography sx={{fontSize:10, color:"#5f5f5f"}}>
                        {hotspot?.timestampAdded && (
                            <span>(since {format(new Date(hotspot?.timestampAdded), "yyyy/MM/dd")})</span>)}
                    </Typography>
                    </>
                )}
                {!earnings && (
                    <>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    </>
                )}
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={6} >
            <Card variant="outlined" sx={{height:102}}>
                <CardContent >
                    {earnings && (
                        <>
                        {thisMonthEarn(earnings.thismonth)} 
                        {lastMonthEarn(earnings.lastmonth)}              
                        </>
                    )}
                    {!earnings && (
                        <>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        </>
                    )}
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <Card sx={{minWidth: 330, width: "100%" }} variant="outlined" >
                <CardContent >
                    <Box sx={{display:'inline-block',  width: '100%'}}>
                        <Typography sx={{float:"left", fontSize:16}}>
                        Reward summary
                        </Typography>
                        <Select value={rewardSelection} sx={{float:"right", fontSize:15, height:24}} onChange={handleRewardSelectionChange} >
                            <MenuItem value={SELECTION_REWARD_7D}>Last 7D</MenuItem>
                            <MenuItem value={SELECTION_REWARD_14D}>Last 14D</MenuItem>
                            <MenuItem value={SELECTION_REWARD_30D}>Last 30D</MenuItem>
                            <MenuItem value={SELECTION_REWARD_THIS_MONTH}>This month</MenuItem>
                            <MenuItem value={SELECTION_REWARD_LAST_MONTH}>Last month</MenuItem>
                        </Select>
                    </Box>
                    
                    {rewardSum &&  (<ComposedChart width={(rewardCardRef.current?.clientWidth??275) - 10} height={120} data={rewardSum}
                        margin={{
                            top: 3,
                            bottom: 8,
                            left: 3,
                            right: 5
                        }}
                        >
                            <Bar dataKey="total" fill="#8884d8" />
                            <Tooltip content={<RewardTooltips/>}/>
                            <Line type="monotone" dataKey="total" stroke="#ff7300" strokeWidth={3}/>
                    </ComposedChart>)}
                    {!rewardSum && (
                        <Skeleton variant="rectangular" width={(rewardCardRef.current?.clientWidth??275) - 10} height={120}/>
                    )}
                </CardContent>
            </Card>
        </Grid>
    </Grid>        
    </>
}

export default RewardInfo;